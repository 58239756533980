/**
 * Get only projects where current user is listed under Project roles
 */
export default {
  methods: {

    // Get attached dashboard block information from ocpu
    // async getdBlocks (type) {
    async dBlockGet (type) {
      // console.log('getdBlocks')
      const ocpuPack = this.$store.state.ocpu.ocpuPack
      const main = this.$store.state.main
      return new Promise((resolve, reject) => {
        this.$httpOcpu.post(`/${ocpuPack}/R/ocdblockget/json`, {
          orgid: main.orgid,
          prjid: main.ap.prjid,
          formid: main.afid,
          bname: type,
        })
          .then(response => {
            // console.log(response)
            if (response.data.length > 0) {
              // Parse json string response from ocpu
              const data = JSON.parse(response.data[0])
              // console.log(data)
              if (type === 'dBlocks') {
                this.$store.commit('main/setdBlocks', data)
                // https://stackoverflow.com/a/35398031
                // console.log(this.$store.state.main.dsb.dBlocks.find(x => x.selected === true).pack)
                // console.log(this.$store.state.main.dsb.dBlocks.filter(x => x.selected === true))
                // console.log(this.$store.state.main.dsb.dBlocks.filter(x => x.selected === true).map(x => x.pack))
              } else if (type === 'fsettings') {
                this.$store.commit('main/setfSettingFull', data)
              } else if (type === 'dDataset') {
                this.$store.commit('main/setdDatasets', data)
                // console.log('foo')
                resolve(response)
              }
            } else {
              // console.log('foof')
              resolve(response)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // Save blocks into ocpu
    // savedBlocks (type, settings) {
    async dBlockSave (type, settings) {
      // console.log(settings)
      const ocpuPack = this.$store.state.ocpu.ocpuPack
      return new Promise((resolve, reject) => {
        // this.getDataStatus.pLocations = 'loading'
        this.$httpOcpu.post(`/${ocpuPack}/R/ocdblocksave/json`, {
          orgid: this.$store.state.main.orgid,
          prjid: this.$store.state.main.ap.prjid,
          formid: this.$store.state.main.afid,
          settings: JSON.stringify(settings),
          bname: type,
        })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              // console.log(response)
              resolve(response)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // checks data in every 5 minutes. TODO move duration into settings
    // Note needs to run command: clearInterval(this.polling) in beforeDestroy () {} if using this
    checkSubmissions () {
      // console.log('checking submissions')
      this.polling = setInterval(() => {
        this.getSubmissions()
      }, 600000)
    },

    // Checks server for new submissions using ocpu 'udacsubmissions' function
    getSubmissions () {
      this.checking = true
      const prop = {
        orgid: this.$store.state.main.orgid,
        prjid: this.$store.state.main.ap.prjid,
        formid: this.$store.state.main.afid,
      }
      const sclient = this.$store.state.main.af.submissions
      // console.log(prop)
      return this.$store
        .dispatch('ocpu/getSubmissions', prop)
        .then(sserver => {
          // console.log(sserver + ' ' + sclient)
          if (sserver > sclient) {
            prop.sserver = sserver
            prop.projectid = this.$store.state.main.af.id
            prop.ix = this.$store.state.main.ix
            // Snackbar text
            this.snackBar = {
              type: 'info',
              mode: 'multi-line',
              title: '',
              text: `There are ${sserver} submissions in the server. You are currently monitoring 
                      ${sclient}. Going to syncronize with the server to bring all data.`,
              visible: true,
            }
            // console.log('server: ' + sserver + ';  client: ' + sclient)
            return this.$store
              .dispatch('ocpu/odataSubmissions', prop)
              .then(() => {
                // Get blocks and data
                this.dBlockGet('dBlocks')
                this.$refs.blockDashboard.getData()
                this.checking = false
              })
          } else {
            this.snackBar = {
              type: 'info',
              mode: 'multi-line',
              direction: 'bottom center',
              timeout: 3000,
              title: '',
              text: `${this.$t('dashboard.latest-data')}`,
              visible: true,
            }
            this.checking = false
          }
          this.checkSbmDate()
          // console.log(sserver)
        })
    },

    checkSbmDate () {
      // console.log('clicked checkSbmDate')
      this.$store.dispatch('main/getCheckDate')
    },

  },
}
